/* if any changes done in this file pls run yarn tailwind:start to incorporate the changes in output.css
index.css is not directly imported now in index.js
*/

/* @tailwind base;  not to override elements styles */

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-visible {
  visibility: visible;
}

.tw-invisible {
  visibility: hidden;
}

.\!tw-static {
  position: static !important;
}

.tw-fixed {
  position: fixed;
}

.\!tw-absolute {
  position: absolute !important;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.tw-inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.\!tw-bottom-\[100px\] {
  bottom: 100px !important;
}

.\!tw-bottom-\[99px\] {
  bottom: 99px !important;
}

.\!tw-left-3 {
  left: 0.75rem !important;
}

.\!tw-left-4 {
  left: 1rem !important;
}

.\!tw-right-3 {
  right: 0.75rem !important;
}

.\!tw-right-\[12px\] {
  right: 12px !important;
}

.\!tw-top-1\/2 {
  top: 50% !important;
}

.\!tw-top-\[12px\] {
  top: 12px !important;
}

.tw-bottom-0 {
  bottom: 0px;
}

.tw-bottom-3 {
  bottom: 0.75rem;
}

.tw-bottom-\[6px\] {
  bottom: 6px;
}

.tw-left-0 {
  left: 0px;
}

.tw-left-1\/2 {
  left: 50%;
}

.tw-left-3 {
  left: 0.75rem;
}

.tw-right-0 {
  right: 0px;
}

.tw-right-\[18px\] {
  right: 18px;
}

.tw-top-0 {
  top: 0px;
}

.tw-top-\[11px\] {
  top: 11px;
}

.tw-top-\[14px\] {
  top: 14px;
}

.tw-top-\[20px\] {
  top: 20px;
}

.tw-top-\[34px\] {
  top: 34px;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-\[100\] {
  z-index: 100;
}

.tw-m-12 {
  margin: 3rem;
}

.tw-m-4 {
  margin: 1rem;
}

.tw-m-8 {
  margin: 2rem;
}

.tw-m-\[48px_0_0_50px\] {
  margin: 48px 0 0 50px;
}

.tw-mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.tw-mx-\[-16px\] {
  margin-left: -16px;
  margin-right: -16px;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-my-\[16px\] {
  margin-top: 16px;
  margin-bottom: 16px;
}

.\!tw--mt-1 {
  margin-top: -0.25rem !important;
}

.\!tw--mt-\[3px\] {
  margin-top: -3px !important;
}

.\!tw-mb-\[24px\] {
  margin-bottom: 24px !important;
}

.\!tw-ml-2 {
  margin-left: 0.5rem !important;
}

.\!tw-mr-1 {
  margin-right: 0.25rem !important;
}

.\!tw-mr-2 {
  margin-right: 0.5rem !important;
}

.\!tw-mr-4 {
  margin-right: 1rem !important;
}

.\!tw-mr-\[-2px\] {
  margin-right: -2px !important;
}

.\!tw-mr-\[6px\] {
  margin-right: 6px !important;
}

.\!tw-mt-0 {
  margin-top: 0px !important;
}

.\!tw-mt-2 {
  margin-top: 0.5rem !important;
}

.\!tw-mt-4 {
  margin-top: 1rem !important;
}

.\!tw-mt-5 {
  margin-top: 1.25rem !important;
}

.\!tw-mt-\[-2px\] {
  margin-top: -2px !important;
}

.\!tw-mt-\[-3px\] {
  margin-top: -3px !important;
}

.\!tw-mt-\[-4px\] {
  margin-top: -4px !important;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-mb-3 {
  margin-bottom: 0.75rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mb-\[10px\] {
  margin-bottom: 10px;
}

.tw-mb-\[12px\] {
  margin-bottom: 12px;
}

.tw-mb-\[14px\] {
  margin-bottom: 14px;
}

.tw-mb-\[20px\] {
  margin-bottom: 20px;
}

.tw-mb-\[32px\] {
  margin-bottom: 32px;
}

.tw-mb-\[42px\] {
  margin-bottom: 42px;
}

.tw-mb-\[54px\] {
  margin-bottom: 54px;
}

.tw-mb-\[6px\] {
  margin-bottom: 6px;
}

.tw-mb-\[9px\] {
  margin-bottom: 9px;
}

.tw-ml-1 {
  margin-left: 0.25rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-ml-3 {
  margin-left: 0.75rem;
}

.tw-ml-\[25px\] {
  margin-left: 25px;
}

.tw-ml-\[4px\] {
  margin-left: 4px;
}

.tw-ml-\[52px\] {
  margin-left: 52px;
}

.tw-mr-1 {
  margin-right: 0.25rem;
}

.tw-mr-2 {
  margin-right: 0.5rem;
}

.tw-mr-4 {
  margin-right: 1rem;
}

.tw-mr-8 {
  margin-right: 2rem;
}

.tw-mr-\[12px\] {
  margin-right: 12px;
}

.tw-mr-\[13px\] {
  margin-right: 13px;
}

.tw-mr-\[5px\] {
  margin-right: 5px;
}

.tw-mt-0 {
  margin-top: 0px;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mt-7 {
  margin-top: 1.75rem;
}

.tw-mt-\[-52px\] {
  margin-top: -52px;
}

.tw-mt-\[-8px\] {
  margin-top: -8px;
}

.tw-mt-\[100px\] {
  margin-top: 100px;
}

.tw-mt-\[10px\] {
  margin-top: 10px;
}

.tw-mt-\[12px\] {
  margin-top: 12px;
}

.tw-mt-\[16px\] {
  margin-top: 16px;
}

.tw-mt-\[2px\] {
  margin-top: 2px;
}

.tw-mt-\[5\.5px\] {
  margin-top: 5.5px;
}

.tw-mt-\[5px\] {
  margin-top: 5px;
}

.tw-mt-\[9px\] {
  margin-top: 9px;
}

.tw-box-border {
  box-sizing: border-box;
}

.tw-box-content {
  box-sizing: content-box;
}

.tw-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.\!tw-block {
  display: block !important;
}

.tw-inline-block {
  display: inline-block;
}

.\!tw-inline {
  display: inline !important;
}

.\!tw-flex {
  display: flex !important;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-aspect-\[16\/9\] {
  aspect-ratio: 16/9;
}

.tw-aspect-\[358\/496\] {
  aspect-ratio: 358/496;
}

.tw-aspect-\[694\/364\] {
  aspect-ratio: 694/364;
}

.\!tw-size-5 {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.\!tw-size-\[14px\] {
  width: 14px !important;
  height: 14px !important;
}

.\!tw-size-\[18px\] {
  width: 18px !important;
  height: 18px !important;
}

.tw-size-16 {
  width: 4rem;
  height: 4rem;
}

.tw-size-2 {
  width: 0.5rem;
  height: 0.5rem;
}

.tw-size-\[6px\] {
  width: 6px;
  height: 6px;
}

.\!tw-h-10 {
  height: 2.5rem !important;
}

.\!tw-h-12 {
  height: 3rem !important;
}

.\!tw-h-20 {
  height: 5rem !important;
}

.\!tw-h-3 {
  height: 0.75rem !important;
}

.\!tw-h-5 {
  height: 1.25rem !important;
}

.\!tw-h-\[14px\] {
  height: 14px !important;
}

.\!tw-h-\[18px\] {
  height: 18px !important;
}

.\!tw-h-\[20px\] {
  height: 20px !important;
}

.\!tw-h-\[328px\] {
  height: 328px !important;
}

.\!tw-h-\[32px\] {
  height: 32px !important;
}

.\!tw-h-\[33px\] {
  height: 33px !important;
}

.\!tw-h-\[36px\] {
  height: 36px !important;
}

.\!tw-h-\[42px\] {
  height: 42px !important;
}

.\!tw-h-\[65vh\] {
  height: 65vh !important;
}

.\!tw-h-auto {
  height: auto !important;
}

.tw-h-20 {
  height: 5rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-\[112px\] {
  height: 112px;
}

.tw-h-\[120px\] {
  height: 120px;
}

.tw-h-\[125px\] {
  height: 125px;
}

.tw-h-\[126px\] {
  height: 126px;
}

.tw-h-\[13\.5px\] {
  height: 13.5px;
}

.tw-h-\[133px\] {
  height: 133px;
}

.tw-h-\[162px\] {
  height: 162px;
}

.tw-h-\[165px\] {
  height: 165px;
}

.tw-h-\[166px\] {
  height: 166px;
}

.tw-h-\[175px\] {
  height: 175px;
}

.tw-h-\[18px\] {
  height: 18px;
}

.tw-h-\[1px\] {
  height: 1px;
}

.tw-h-\[20px\] {
  height: 20px;
}

.tw-h-\[228px\] {
  height: 228px;
}

.tw-h-\[236px\] {
  height: 236px;
}

.tw-h-\[24px\] {
  height: 24px;
}

.tw-h-\[25px\] {
  height: 25px;
}

.tw-h-\[38px\] {
  height: 38px;
}

.tw-h-\[405px\] {
  height: 405px;
}

.tw-h-\[40px\] {
  height: 40px;
}

.tw-h-\[42px\] {
  height: 42px;
}

.tw-h-\[64px\] {
  height: 64px;
}

.tw-h-\[68px\] {
  height: 68px;
}

.tw-h-\[82px\] {
  height: 82px;
}

.tw-h-\[8px\] {
  height: 8px;
}

.tw-h-\[calc\(100\%-24px\)\] {
  height: calc(100% - 24px);
}

.tw-h-\[calc\(100dvh-48px\)\] {
  height: calc(100dvh - 48px);
}

.tw-h-\[calc\(100dvh-64px\)\] {
  height: calc(100dvh - 64px);
}

.tw-h-auto {
  height: auto;
}

.tw-h-full {
  height: 100%;
}

.tw-max-h-9 {
  max-height: 2.25rem;
}

.tw-max-h-\[31px\] {
  max-height: 31px;
}

.\!tw-min-h-\[305px\] {
  min-height: 305px !important;
}

.\!tw-min-h-\[350px\] {
  min-height: 350px !important;
}

.tw-min-h-\[181px\] {
  min-height: 181px;
}

.tw-min-h-\[42px\] {
  min-height: 42px;
}

.tw-min-h-\[75\.23px\] {
  min-height: 75.23px;
}

.\!tw-w-10 {
  width: 2.5rem !important;
}

.\!tw-w-12 {
  width: 3rem !important;
}

.\!tw-w-3 {
  width: 0.75rem !important;
}

.\!tw-w-5 {
  width: 1.25rem !important;
}

.\!tw-w-\[129px\] {
  width: 129px !important;
}

.\!tw-w-\[14px\] {
  width: 14px !important;
}

.\!tw-w-\[18px\] {
  width: 18px !important;
}

.\!tw-w-\[20px\] {
  width: 20px !important;
}

.\!tw-w-\[275px\] {
  width: 275px !important;
}

.\!tw-w-\[340px\] {
  width: 340px !important;
}

.\!tw-w-\[380px\] {
  width: 380px !important;
}

.\!tw-w-\[38px\] {
  width: 38px !important;
}

.\!tw-w-\[43\%\] {
  width: 43% !important;
}

.\!tw-w-\[65\%\] {
  width: 65% !important;
}

.\!tw-w-full {
  width: 100% !important;
}

.tw-w-3 {
  width: 0.75rem;
}

.tw-w-3\/4 {
  width: 75%;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-\[0\.75px\] {
  width: 0.75px;
}

.tw-w-\[154px\] {
  width: 154px;
}

.tw-w-\[18px\] {
  width: 18px;
}

.tw-w-\[200px\] {
  width: 200px;
}

.tw-w-\[20px\] {
  width: 20px;
}

.tw-w-\[210px\] {
  width: 210px;
}

.tw-w-\[229px\] {
  width: 229px;
}

.tw-w-\[24px\] {
  width: 24px;
}

.tw-w-\[250px\] {
  width: 250px;
}

.tw-w-\[275px\] {
  width: 275px;
}

.tw-w-\[340px\] {
  width: 340px;
}

.tw-w-\[429px\] {
  width: 429px;
}

.tw-w-\[43\%\] {
  width: 43%;
}

.tw-w-\[60px\] {
  width: 60px;
}

.tw-w-\[70\%\] {
  width: 70%;
}

.tw-w-\[75px\] {
  width: 75px;
}

.tw-w-\[8px\] {
  width: 8px;
}

.tw-w-\[calc\(100\%-16px\)\] {
  width: calc(100% - 16px);
}

.tw-w-\[calc\(100\%-24px\)\] {
  width: calc(100% - 24px);
}

.tw-w-\[calc\(100vw-24px\)\] {
  width: calc(100vw - 24px);
}

.tw-w-auto {
  width: auto;
}

.tw-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.tw-w-full {
  width: 100%;
}

.tw-w-screen {
  width: 100vw;
}

.\!tw-min-w-0 {
  min-width: 0px !important;
}

.\!tw-min-w-\[200px\] {
  min-width: 200px !important;
}

.\!tw-min-w-\[29px\] {
  min-width: 29px !important;
}

.\!tw-min-w-\[406px\] {
  min-width: 406px !important;
}

.\!tw-min-w-\[410px\] {
  min-width: 410px !important;
}

.\!tw-min-w-\[438px\] {
  min-width: 438px !important;
}

.\!tw-min-w-\[unset\] {
  min-width: unset !important;
}

.tw-min-w-4 {
  min-width: 1rem;
}

.tw-min-w-\[159px\] {
  min-width: 159px;
}

.tw-min-w-\[200px\] {
  min-width: 200px;
}

.tw-min-w-\[604px\] {
  min-width: 604px;
}

.tw-min-w-\[719px\] {
  min-width: 719px;
}

.tw-min-w-\[730px\] {
  min-width: 730px;
}

.tw-min-w-\[879px\] {
  min-width: 879px;
}

.tw-min-w-\[unset\] {
  min-width: unset;
}

.\!tw-max-w-\[100px\] {
  max-width: 100px !important;
}

.\!tw-max-w-none {
  max-width: none !important;
}

.tw-max-w-\[245px\] {
  max-width: 245px;
}

.tw-max-w-\[259px\] {
  max-width: 259px;
}

.tw-max-w-\[318px\] {
  max-width: 318px;
}

.tw-max-w-\[50vw\] {
  max-width: 50vw;
}

.tw-max-w-\[65\%\] {
  max-width: 65%;
}

.\!tw-flex-1 {
  flex: 1 1 0% !important;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-flex-\[1_0_0\] {
  flex: 1 0 0;
}

.tw-flex-none {
  flex: none;
}

.\!tw-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.tw-flex-shrink-0 {
  flex-shrink: 0;
}

.tw-rotate-45 {
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.\!tw-animate-spin {
  -webkit-animation: tw-spin 1s linear infinite !important;
  animation: tw-spin 1s linear infinite !important;
}

.\!tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tw-grid-cols-\[3fr_8fr\] {
  grid-template-columns: 3fr 8fr;
}

.tw-flex-row {
  flex-direction: row;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-place-content-center {
  place-content: center;
}

.tw-content-start {
  align-content: flex-start;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.\!tw-items-center {
  align-items: center !important;
}

.tw-items-center {
  align-items: center;
}

.\!tw-justify-start {
  justify-content: flex-start !important;
}

.\!tw-justify-center {
  justify-content: center !important;
}

.tw-justify-center {
  justify-content: center;
}

.\!tw-justify-between {
  justify-content: space-between !important;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.tw-gap-0\.5 {
  gap: 0.125rem;
}

.tw-gap-1 {
  gap: 0.25rem;
}

.tw-gap-2 {
  gap: 0.5rem;
}

.tw-gap-2\.5 {
  gap: 0.625rem;
}

.tw-gap-3 {
  gap: 0.75rem;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-gap-5 {
  gap: 1.25rem;
}

.tw-gap-6 {
  gap: 1.5rem;
}

.tw-gap-7 {
  gap: 1.75rem;
}

.tw-gap-8 {
  gap: 2rem;
}

.tw-gap-9 {
  gap: 2.25rem;
}

.tw-gap-\[10px\] {
  gap: 10px;
}

.tw-gap-\[11px\] {
  gap: 11px;
}

.tw-gap-\[12px\] {
  gap: 12px;
}

.tw-gap-\[14px\] {
  gap: 14px;
}

.tw-gap-\[15px\] {
  gap: 15px;
}

.tw-gap-\[16px\] {
  gap: 16px;
}

.tw-gap-\[22px\] {
  gap: 22px;
}

.tw-gap-\[24px\] {
  gap: 24px;
}

.tw-gap-\[26px\] {
  gap: 26px;
}

.tw-gap-\[2px\] {
  gap: 2px;
}

.tw-gap-\[4px\] {
  gap: 4px;
}

.tw-gap-\[8px\] {
  gap: 8px;
}

.tw-gap-\[9px\] {
  gap: 9px;
}

.tw-gap-x-3 {
  -webkit-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.tw-gap-x-4 {
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.tw-gap-y-2 {
  row-gap: 0.5rem;
}

.tw-self-end {
  align-self: flex-end;
}

.tw-self-stretch {
  align-self: stretch;
}

.tw-overflow-auto {
  overflow: auto;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-x-auto {
  overflow-x: auto;
}

.tw-text-ellipsis {
  text-overflow: ellipsis;
}

.\!tw-whitespace-nowrap {
  white-space: nowrap !important;
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.tw-break-all {
  word-break: break-all;
}

.\!tw-rounded-\[12px\] {
  border-radius: 12px !important;
}

.\!tw-rounded-\[5px\] {
  border-radius: 5px !important;
}

.\!tw-rounded-lg {
  border-radius: 0.5rem !important;
}

.\!tw-rounded-md {
  border-radius: 0.375rem !important;
}

.\!tw-rounded-none {
  border-radius: 0px !important;
}

.tw-rounded {
  border-radius: 0.25rem;
}

.tw-rounded-3xl {
  border-radius: 1.5rem;
}

.tw-rounded-\[10px\] {
  border-radius: 10px;
}

.tw-rounded-\[11px\] {
  border-radius: 11px;
}

.tw-rounded-\[12px\] {
  border-radius: 12px;
}

.tw-rounded-\[16px\] {
  border-radius: 16px;
}

.tw-rounded-\[18px\] {
  border-radius: 18px;
}

.tw-rounded-\[24px\] {
  border-radius: 24px;
}

.tw-rounded-\[2px\] {
  border-radius: 2px;
}

.tw-rounded-\[3px\] {
  border-radius: 3px;
}

.tw-rounded-\[4px\] {
  border-radius: 4px;
}

.tw-rounded-\[5px\] {
  border-radius: 5px;
}

.tw-rounded-\[6px\] {
  border-radius: 6px;
}

.tw-rounded-\[8px\] {
  border-radius: 8px;
}

.tw-rounded-\[9px\] {
  border-radius: 9px;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: 0.5rem;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-rounded-xl {
  border-radius: 0.75rem;
}

.\!tw-rounded-bl-none {
  border-bottom-left-radius: 0px !important;
}

.\!tw-rounded-br-none {
  border-bottom-right-radius: 0px !important;
}

.tw-rounded-bl-\[5px\] {
  border-bottom-left-radius: 5px;
}

.tw-rounded-br-\[5px\] {
  border-bottom-right-radius: 5px;
}

.\!tw-border {
  border-width: 1px !important;
}

.\!tw-border-\[1\.5px\] {
  border-width: 1.5px !important;
}

.tw-border {
  border-width: 1px;
}

.tw-border-0 {
  border-width: 0px;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-\[0\.75px\] {
  border-width: 0.75px;
}

.tw-border-\[0px\] {
  border-width: 0px;
}

.tw-border-\[1\.5px\] {
  border-width: 1.5px;
}

.\!tw-border-b-\[1px\] {
  border-bottom-width: 1px !important;
}

.\!tw-border-l-\[3px\] {
  border-left-width: 3px !important;
}

.\!tw-border-t-\[1px\] {
  border-top-width: 1px !important;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-b-0 {
  border-bottom-width: 0px;
}

.tw-border-b-\[0\.5px\] {
  border-bottom-width: 0.5px;
}

.tw-border-b-\[1px\] {
  border-bottom-width: 1px;
}

.tw-border-l-2 {
  border-left-width: 2px;
}

.tw-border-l-\[3px\] {
  border-left-width: 3px;
}

.tw-border-r {
  border-right-width: 1px;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-t-2 {
  border-top-width: 2px;
}

.tw-border-t-\[0\.5px\] {
  border-top-width: 0.5px;
}

.tw-border-t-\[1px\] {
  border-top-width: 1px;
}

.\!tw-border-solid {
  border-style: solid !important;
}

.tw-border-solid {
  border-style: solid;
}

.tw-border-dashed {
  border-style: dashed;
}

.\!tw-border-none {
  border-style: none !important;
}

.\!tw-border-\[\#493AB1\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(73 58 177 / var(--tw-border-opacity, 1)) !important;
}

.\!tw-border-\[\#C0392B\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(192 57 43 / var(--tw-border-opacity, 1)) !important;
}

.\!tw-border-\[\#DBD7EE\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(219 215 238 / var(--tw-border-opacity, 1)) !important;
}

.\!tw-border-\[\#DBD9E6\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(219 217 230 / var(--tw-border-opacity, 1)) !important;
}

.tw-border-\[\#493ab1\] {
  --tw-border-opacity: 1;
  border-color: rgb(73 58 177 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#7365CD\] {
  --tw-border-opacity: 1;
  border-color: rgb(115 101 205 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#CFCDC9\] {
  --tw-border-opacity: 1;
  border-color: rgb(207 205 201 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#D9D9D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#DBD7EE\] {
  --tw-border-opacity: 1;
  border-color: rgb(219 215 238 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#E4E4E4\] {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 228 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#E7E6EA\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 230 234 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#EFEEF1\] {
  --tw-border-opacity: 1;
  border-color: rgb(239 238 241 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#EFF0F6\] {
  --tw-border-opacity: 1;
  border-color: rgb(239 240 246 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#aedfbd\] {
  --tw-border-opacity: 1;
  border-color: rgb(174 223 189 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#bfd0f4\] {
  --tw-border-opacity: 1;
  border-color: rgb(191 208 244 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#ccc3fa\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 195 250 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#e7e4fb\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 228 251 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#e7e6e4\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 230 228 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#e8e8e8\] {
  --tw-border-opacity: 1;
  border-color: rgb(232 232 232 / var(--tw-border-opacity, 1));
}

.tw-border-\[\#eeecf8\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 236 248 / var(--tw-border-opacity, 1));
}

.tw-border-\[rgba\(228\,228\,228\,0\.3\)\] {
  border-color: rgba(228, 228, 228, 0.3);
}

.tw-border-\[rgba\(39\,37\,34\,0\.20\)\] {
  border-color: rgba(39, 37, 34, 0.2);
}

.\!tw-border-b-\[\#E7E6E4\] {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(
    231 230 228 / var(--tw-border-opacity, 1)
  ) !important;
}

.tw-border-b-\[\#EEEDEC\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(238 237 236 / var(--tw-border-opacity, 1));
}

.tw-border-b-\[\#e7e6e4\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(231 230 228 / var(--tw-border-opacity, 1));
}

.tw-border-b-\[\#e8e8e8\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(232 232 232 / var(--tw-border-opacity, 1));
}

.tw-border-b-\[rgba\(228\,228\,228\,0\.5\)\] {
  border-bottom-color: rgba(228, 228, 228, 0.5);
}

.tw-border-l-\[\#493ab1\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(73 58 177 / var(--tw-border-opacity, 1));
}

.tw-border-l-\[\#C4C8E4\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(196 200 228 / var(--tw-border-opacity, 1));
}

.tw-border-r-\[\#e4e4e4\] {
  --tw-border-opacity: 1;
  border-right-color: rgb(228 228 228 / var(--tw-border-opacity, 1));
}

.tw-border-t-\[\#C3BEEA\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(195 190 234 / var(--tw-border-opacity, 1));
}

.tw-border-t-\[\#C4C8E4\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(196 200 228 / var(--tw-border-opacity, 1));
}

.tw-border-t-\[\#e7e6e4\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(231 230 228 / var(--tw-border-opacity, 1));
}

.\!tw-bg-\[\#00B779\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 183 121 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-\[\#5243C240\] {
  background-color: #5243c240 !important;
}

.\!tw-bg-\[\#7465BE\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(116 101 190 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-\[\#BF260033\] {
  background-color: #bf260033 !important;
}

.\!tw-bg-\[\#DED9FF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(222 217 255 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-\[\#DF635D\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(223 99 93 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-\[\#E5F0FF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 240 255 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-\[\#eae7fa\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 231 250 / var(--tw-bg-opacity, 1)) !important;
}

.\!tw-bg-transparent {
  background-color: transparent !important;
}

.\!tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.tw-bg-\[\#00996633\] {
  background-color: #00996633;
}

.tw-bg-\[\#009966\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 153 102 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#00B779\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 183 121 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#479d1e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(71 157 30 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#493AB1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(73 58 177 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#493ab1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(73 58 177 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#7365CD4D\] {
  background-color: #7365cd4d;
}

.tw-bg-\[\#BF260033\] {
  background-color: #bf260033;
}

.tw-bg-\[\#BF2600\] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 38 0 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#D4FFF0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 255 240 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#D9D9D9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#DCD9F2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 242 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#DF645D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 100 93 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#E0F5EE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 245 238 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#E5FFF7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 255 247 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#E9E4F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 228 246 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#EBE8FD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 232 253 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#F8F8F8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#FBEBEA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 235 234 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#FDF7EC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 247 236 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#FFF3D9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 217 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#b0e9ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(176 233 255 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#ca0315\] {
  --tw-bg-opacity: 1;
  background-color: rgb(202 3 21 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#d4fff0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 255 240 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#dff9e7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 249 231 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#e4e1fc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 225 252 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#e5f0ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 240 255 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#e7e4fb50\] {
  background-color: #e7e4fb50;
}

.tw-bg-\[\#eae7fa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 231 250 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#ecf2ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 242 255 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#f0fdf7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 247 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#f3f2ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 255 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#f5f3fb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 251 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#f7f7f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 248 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#fafafa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[\#fff3d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 217 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[rgb\(255\,171\,0\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 171 0 / var(--tw-bg-opacity, 1));
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.tw-bg-\[linear-gradient\(100deg\,_rgba\(181\,151\,246\,0\.40\)_0\%\,_rgba\(150\,198\,234\,0\.40\)_100\.73\%\)\] {
  background-image: linear-gradient(
    100deg,
    rgba(181, 151, 246, 0.4) 0%,
    rgba(150, 198, 234, 0.4) 100.73%
  );
}

.tw-bg-\[linear-gradient\(108\.96deg\,\#5e49f1_0\%\,\#eb5ded_100\%\)\] {
  background-image: linear-gradient(108.96deg, #5e49f1 0%, #eb5ded 100%);
}

.tw-bg-\[linear-gradient\(125deg\,\#FDF6DB_9\.9\%\,\#FEFEEF_45\.45\%\,\#FDF9DB_81\.01\%\)\] {
  background-image: linear-gradient(
    125deg,
    #fdf6db 9.9%,
    #fefeef 45.45%,
    #fdf9db 81.01%
  );
}

.tw-bg-\[linear-gradient\(125deg\,\#dfdbfd_9\.9\%\,\#f1effe_45\.45\%\,\#dfdbfd_81\.01\%\)\] {
  background-image: linear-gradient(
    125deg,
    #dfdbfd 9.9%,
    #f1effe 45.45%,
    #dfdbfd 81.01%
  );
}

.tw-bg-\[linear-gradient\(125deg\,\#fdf6db_9\.9\%\,\#fefeef_45\.45\%\,\#fdf9db_81\.01\%\)\] {
  background-image: linear-gradient(
    125deg,
    #fdf6db 9.9%,
    #fefeef 45.45%,
    #fdf9db 81.01%
  );
}

.tw-bg-\[linear-gradient\(130deg\,rgba\(161\,252\,254\,0\.7\)\,rgba\(93\,129\,255\,0\.7\)\,rgba\(25\,5\,255\,0\.7\)\,rgba\(118\,100\,238\,0\.7\)\,rgba\(171\,128\,247\,0\.7\)\,rgba\(223\,156\,255\,0\.7\)\,rgba\(25\,5\,255\,0\.7\)\,rgba\(161\,252\,254\,0\.7\)\)\] {
  background-image: linear-gradient(
    130deg,
    rgba(161, 252, 254, 0.7),
    rgba(93, 129, 255, 0.7),
    rgba(25, 5, 255, 0.7),
    rgba(118, 100, 238, 0.7),
    rgba(171, 128, 247, 0.7),
    rgba(223, 156, 255, 0.7),
    rgba(25, 5, 255, 0.7),
    rgba(161, 252, 254, 0.7)
  );
}

.tw-bg-\[linear-gradient\(180deg\,_\#7365CD_-156\.7\%\,_rgba\(115\,101\,205\,0\.00\)_100\%\)\] {
  background-image: linear-gradient(
    180deg,
    #7365cd -156.7%,
    rgba(115, 101, 205, 0) 100%
  );
}

.tw-bg-\[linear-gradient\(212\.58deg\,\#7365cd_14\.79\%\,\#40329a_98\.64\%\)\] {
  background-image: linear-gradient(212.58deg, #7365cd 14.79%, #40329a 98.64%);
}

.tw-bg-\[linear-gradient\(213deg\,rgba\(59\,87\,157\,0\.53\)_14\.79\%\,\#3b579d_98\.64\%\)\] {
  background-image: linear-gradient(
    213deg,
    rgba(59, 87, 157, 0.53) 14.79%,
    #3b579d 98.64%
  );
}

.tw-bg-\[linear-gradient\(264deg\,_rgba\(238\,236\,249\,0\.80\)_0\%\,_\#E0DCF4_99\.78\%\)\] {
  background-image: linear-gradient(
    264deg,
    rgba(238, 236, 249, 0.8) 0%,
    #e0dcf4 99.78%
  );
}

.tw-bg-\[linear-gradient\(347deg\,_\#D397FA_9\.22\%\,_\#493AB1_86\.29\%\)\] {
  background-image: linear-gradient(347deg, #d397fa 9.22%, #493ab1 86.29%);
}

.tw-bg-\[linear-gradient\(347deg\,_\#D397FA_9\.22\%\,_\#8364E8_86\.29\%\)\] {
  background-image: linear-gradient(347deg, #d397fa 9.22%, #8364e8 86.29%);
}

.tw-bg-\[linear-gradient\(91deg\,_\#E84855_-9\.86\%\,_\#993955_126\.97\%\)\] {
  background-image: linear-gradient(91deg, #e84855 -9.86%, #993955 126.97%);
}

.tw-bg-\[linear-gradient\(94deg\,\#2c207e_-10\.84\%\,\#7369ef_44\.25\%\,\#2c207e_95\.13\%\)\] {
  background-image: linear-gradient(
    94deg,
    #2c207e -10.84%,
    #7369ef 44.25%,
    #2c207e 95.13%
  );
}

.tw-bg-\[linear-gradient\(98deg\,_\#EBEDFF_0\%\,_\#D7D3F8_100\%\)\] {
  background-image: linear-gradient(98deg, #ebedff 0%, #d7d3f8 100%);
}

.tw-bg-\[linear-gradient\(rgba\(255\,203\,100\,1\)\,rgba\(255\,171\,3\,1\)\)\] {
  background-image: linear-gradient(
    rgba(255, 203, 100, 1),
    rgba(255, 171, 3, 1)
  );
}

.tw-bg-\[radial-gradient\(141\.42\%_141\.42\%_at_0\%_0\%\,_\#DCD9F2_0\%\,_\#EEECF9_100\%\)\] {
  background-image: radial-gradient(
    141.42% 141.42% at 0% 0%,
    #dcd9f2 0%,
    #eeecf9 100%
  );
}

.tw-bg-\[radial-gradient\(172\.23\%_206\.42\%_at_60\.45\%_42\.19\%\,_\#40329A_0\%\,_\#7365CD_100\%\)\] {
  background-image: radial-gradient(
    172.23% 206.42% at 60.45% 42.19%,
    #40329a 0%,
    #7365cd 100%
  );
}

.tw-bg-\[radial-gradient\(839\.49\%_87\.11\%_at_50\%_50\%\,_\#7365CD_0\%\,_\#40329A_100\%\)\] {
  background-image: radial-gradient(
    839.49% 87.11% at 50% 50%,
    #7365cd 0%,
    #40329a 100%
  );
}

.tw-bg-\[radial-gradient\(90\.38\%_90\.39\%_at_50\%_62\.22\%\,_\#FFF_0\%\,_\#DFDBFD_100\%\)\] {
  background-image: radial-gradient(
    90.38% 90.39% at 50% 62.22%,
    #fff 0%,
    #dfdbfd 100%
  );
}

.tw-bg-\[repeating-linear-gradient\(to_right\,\#C4C8E4_0\,\#C4C8E4_5px\,transparent_6px\,transparent_10px\)\] {
  background-image: repeating-linear-gradient(
    to right,
    #c4c8e4 0,
    #c4c8e4 5px,
    transparent 6px,
    transparent 10px
  );
}

.tw-bg-none {
  background-image: none;
}

.tw-bg-cover {
  background-size: cover;
}

.tw-bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.tw-bg-right {
  background-position: right;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat;
}

.tw-fill-\[\#332200\] {
  fill: #332200;
}

.tw-object-cover {
  object-fit: cover;
}

.\!tw-p-0 {
  padding: 0px !important;
}

.\!tw-p-4 {
  padding: 1rem !important;
}

.\!tw-p-6 {
  padding: 1.5rem !important;
}

.\!tw-p-\[12px_16px\] {
  padding: 12px 16px !important;
}

.\!tw-p-\[24px\] {
  padding: 24px !important;
}

.tw-p-0 {
  padding: 0px;
}

.tw-p-0\.5 {
  padding: 0.125rem;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-2\.5 {
  padding: 0.625rem;
}

.tw-p-3 {
  padding: 0.75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-\[1\.5px\] {
  padding: 1.5px;
}

.tw-p-\[14px_16px_12px_16px\] {
  padding: 14px 16px 12px 16px;
}

.tw-p-\[16px_20px\] {
  padding: 16px 20px;
}

.tw-p-\[20px\] {
  padding: 20px;
}

.tw-p-\[21px_18px_12px\] {
  padding: 21px 18px 12px;
}

.tw-p-\[24px\] {
  padding: 24px;
}

.tw-p-\[8px_6px\] {
  padding: 8px 6px;
}

.\!tw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.\!tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!tw-px-\[16px\] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.\!tw-px-\[24px\] {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.\!tw-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.\!tw-py-\[14px\] {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.\!tw-py-\[16px\] {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.\!tw-py-\[24px\] {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.tw-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tw-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tw-px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.tw-px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.tw-px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.tw-px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.tw-px-\[17\.5px\] {
  padding-left: 17.5px;
  padding-right: 17.5px;
}

.tw-px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.tw-px-\[35px\] {
  padding-left: 35px;
  padding-right: 35px;
}

.tw-px-\[4\.75px\] {
  padding-left: 4.75px;
  padding-right: 4.75px;
}

.tw-px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.tw-px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.tw-py-\[10\.5px\] {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.tw-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tw-py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}

.tw-py-\[2\.37px\] {
  padding-top: 2.37px;
  padding-bottom: 2.37px;
}

.tw-py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.tw-py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.tw-py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.tw-py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tw-py-\[6\.25px\] {
  padding-top: 6.25px;
  padding-bottom: 6.25px;
}

.tw-py-\[8\.25px\] {
  padding-top: 8.25px;
  padding-bottom: 8.25px;
}

.tw-py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.\!tw-pb-\[16px\] {
  padding-bottom: 16px !important;
}

.\!tw-pl-0 {
  padding-left: 0px !important;
}

.\!tw-pr-0 {
  padding-right: 0px !important;
}

.\!tw-pt-\[40px\] {
  padding-top: 40px !important;
}

.tw-pb-1 {
  padding-bottom: 0.25rem;
}

.tw-pb-10 {
  padding-bottom: 2.5rem;
}

.tw-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pb-3 {
  padding-bottom: 0.75rem;
}

.tw-pb-\[15px\] {
  padding-bottom: 15px;
}

.tw-pb-\[20px\] {
  padding-bottom: 20px;
}

.tw-pb-\[24px\] {
  padding-bottom: 24px;
}

.tw-pb-\[6px\] {
  padding-bottom: 6px;
}

.tw-pl-2\.5 {
  padding-left: 0.625rem;
}

.tw-pl-5 {
  padding-left: 1.25rem;
}

.tw-pl-\[19px\] {
  padding-left: 19px;
}

.tw-pl-\[20px\] {
  padding-left: 20px;
}

.tw-pl-\[24px\] {
  padding-left: 24px;
}

.tw-pl-\[5px\] {
  padding-left: 5px;
}

.tw-pr-2 {
  padding-right: 0.5rem;
}

.tw-pr-\[10px\] {
  padding-right: 10px;
}

.tw-pr-\[20px\] {
  padding-right: 20px;
}

.tw-pr-\[50px\] {
  padding-right: 50px;
}

.tw-pt-3 {
  padding-top: 0.75rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pt-5 {
  padding-top: 1.25rem;
}

.tw-pt-\[20px\] {
  padding-top: 20px;
}

.\!tw-text-center {
  text-align: center !important;
}

.tw-text-center {
  text-align: center;
}

.tw-text-right {
  text-align: right;
}

.tw-text-start {
  text-align: start;
}

.\!tw-text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.\!tw-text-\[12px\] {
  font-size: 12px !important;
}

.\!tw-text-\[14px\] {
  font-size: 14px !important;
}

.\!tw-text-\[16px\] {
  font-size: 16px !important;
}

.\!tw-text-\[18px\] {
  font-size: 18px !important;
}

.\!tw-text-\[1rem\] {
  font-size: 1rem !important;
}

.\!tw-text-\[20px\] {
  font-size: 20px !important;
}

.\!tw-text-\[24px\] {
  font-size: 24px !important;
}

.\!tw-text-\[28px\] {
  font-size: 28px !important;
}

.\!tw-text-\[52px\] {
  font-size: 52px !important;
}

.\!tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.\!tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.\!tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.\!tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.\!tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-\[0\.75rem\] {
  font-size: 0.75rem;
}

.tw-text-\[0\.875rem\] {
  font-size: 0.875rem;
}

.tw-text-\[10px\] {
  font-size: 10px;
}

.tw-text-\[11px\] {
  font-size: 11px;
}

.tw-text-\[12px\] {
  font-size: 12px;
}

.tw-text-\[13px\] {
  font-size: 13px;
}

.tw-text-\[14px\] {
  font-size: 14px;
}

.tw-text-\[16px\] {
  font-size: 16px;
}

.tw-text-\[18px\] {
  font-size: 18px;
}

.tw-text-\[1rem\] {
  font-size: 1rem;
}

.tw-text-\[20px\] {
  font-size: 20px;
}

.tw-text-\[22px\] {
  font-size: 22px;
}

.tw-text-\[8px\] {
  font-size: 8px;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.\!tw-font-bold {
  font-weight: 700 !important;
}

.\!tw-font-medium {
  font-weight: 500 !important;
}

.tw-font-\[400\] {
  font-weight: 400;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-light {
  font-weight: 300;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-uppercase {
  text-transform: uppercase;
}

.\!tw-normal-case {
  text-transform: none !important;
}

.tw-not-italic {
  font-style: normal;
}

.\!tw-leading-\[normal\] {
  line-height: normal !important;
}

.\!tw-leading-normal {
  line-height: 1.5 !important;
}

.tw-leading-\[11px\] {
  line-height: 11px;
}

.tw-leading-\[127\.1\%\] {
  line-height: 127.1%;
}

.tw-leading-\[133\.1\%\] {
  line-height: 133.1%;
}

.tw-leading-\[137\.1\%\] {
  line-height: 137.1%;
}

.tw-leading-\[14\.32px\] {
  line-height: 14.32px;
}

.tw-leading-\[15px\] {
  line-height: 15px;
}

.tw-leading-\[16px\] {
  line-height: 16px;
}

.tw-leading-\[17px\] {
  line-height: 17px;
}

.tw-leading-\[18px\] {
  line-height: 18px;
}

.tw-leading-\[19\.54px\] {
  line-height: 19.54px;
}

.tw-leading-\[19px\] {
  line-height: 19px;
}

.tw-leading-\[21\.94px\] {
  line-height: 21.94px;
}

.tw-leading-\[21px\] {
  line-height: 21px;
}

.tw-leading-\[22px\] {
  line-height: 22px;
}

.tw-leading-\[24\.68px\] {
  line-height: 24.68px;
}

.tw-leading-\[24px\] {
  line-height: 24px;
}

.tw-leading-\[28px\] {
  line-height: 28px;
}

.tw-leading-\[normal\] {
  line-height: normal;
}

.tw-leading-none {
  line-height: 1;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-tracking-\[0\.18px\] {
  letter-spacing: 0.18px;
}

.tw-tracking-\[0\.22px\] {
  letter-spacing: 0.22px;
}

.tw-tracking-\[0\.23px\] {
  letter-spacing: 0.23px;
}

.tw-tracking-\[0\.24px\] {
  letter-spacing: 0.24px;
}

.tw-tracking-\[0\.28px\] {
  letter-spacing: 0.28px;
}

.tw-tracking-\[0\.2px\] {
  letter-spacing: 0.2px;
}

.tw-tracking-\[0\.32px\] {
  letter-spacing: 0.32px;
}

.tw-tracking-\[0\.36px\] {
  letter-spacing: 0.36px;
}

.tw-tracking-\[0\.48px\] {
  letter-spacing: 0.48px;
}

.tw-tracking-\[0\.56px\] {
  letter-spacing: 0.56px;
}

.tw-tracking-\[0\.64px\] {
  letter-spacing: 0.64px;
}

.tw-tracking-\[2\%\] {
  letter-spacing: 2%;
}

.\!tw-text-\[\#009966\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 153 102 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#272522\] {
  --tw-text-opacity: 1 !important;
  color: rgb(39 37 34 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#2e7b1c\] {
  --tw-text-opacity: 1 !important;
  color: rgb(46 123 28 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#396cdf\] {
  --tw-text-opacity: 1 !important;
  color: rgb(57 108 223 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#493AB1\] {
  --tw-text-opacity: 1 !important;
  color: rgb(73 58 177 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#493ab1\] {
  --tw-text-opacity: 1 !important;
  color: rgb(73 58 177 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#7365CD\] {
  --tw-text-opacity: 1 !important;
  color: rgb(115 101 205 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#BEBEBE\] {
  --tw-text-opacity: 1 !important;
  color: rgb(190 190 190 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#BF2600\] {
  --tw-text-opacity: 1 !important;
  color: rgb(191 38 0 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#C0392B\] {
  --tw-text-opacity: 1 !important;
  color: rgb(192 57 43 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#d5d2f0\] {
  --tw-text-opacity: 1 !important;
  color: rgb(213 210 240 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[\#fcfbfe\] {
  --tw-text-opacity: 1 !important;
  color: rgb(252 251 254 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-\[rgb\(110_103_94\)\] {
  --tw-text-opacity: 1 !important;
  color: rgb(110 103 94 / var(--tw-text-opacity, 1)) !important;
}

.\!tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.tw-text-\[\#000000D9\] {
  color: #000000d9;
}

.tw-text-\[\#0052cc\] {
  --tw-text-opacity: 1;
  color: rgb(0 82 204 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#009966\] {
  --tw-text-opacity: 1;
  color: rgb(0 153 102 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#00A540\] {
  --tw-text-opacity: 1;
  color: rgb(0 165 64 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#00B779\] {
  --tw-text-opacity: 1;
  color: rgb(0 183 121 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#00b779\] {
  --tw-text-opacity: 1;
  color: rgb(0 183 121 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#03b77a\] {
  --tw-text-opacity: 1;
  color: rgb(3 183 122 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#055575\] {
  --tw-text-opacity: 1;
  color: rgb(5 85 117 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#0B0069\] {
  --tw-text-opacity: 1;
  color: rgb(11 0 105 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#1C274C\] {
  --tw-text-opacity: 1;
  color: rgb(28 39 76 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#231D4F\] {
  --tw-text-opacity: 1;
  color: rgb(35 29 79 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#26264F\] {
  --tw-text-opacity: 1;
  color: rgb(38 38 79 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#27252280\] {
  color: #27252280;
}

.tw-text-\[\#27252299\] {
  color: #27252299;
}

.tw-text-\[\#272522\] {
  --tw-text-opacity: 1;
  color: rgb(39 37 34 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#272522d9\] {
  color: #272522d9;
}

.tw-text-\[\#291a93\] {
  --tw-text-opacity: 1;
  color: rgb(41 26 147 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#2c207e\] {
  --tw-text-opacity: 1;
  color: rgb(44 32 126 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#2e3033\] {
  --tw-text-opacity: 1;
  color: rgb(46 48 51 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#332200\] {
  --tw-text-opacity: 1;
  color: rgb(51 34 0 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#493AB1CC\] {
  color: #493ab1cc;
}

.tw-text-\[\#493AB1\] {
  --tw-text-opacity: 1;
  color: rgb(73 58 177 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#493ab1\] {
  --tw-text-opacity: 1;
  color: rgb(73 58 177 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#4F4E5C\] {
  --tw-text-opacity: 1;
  color: rgb(79 78 92 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#4d4d4d\] {
  --tw-text-opacity: 1;
  color: rgb(77 77 77 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#4f4e5c\] {
  --tw-text-opacity: 1;
  color: rgb(79 78 92 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#5243C2\] {
  --tw-text-opacity: 1;
  color: rgb(82 67 194 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#524E47\] {
  --tw-text-opacity: 1;
  color: rgb(82 78 71 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#5947CF\] {
  --tw-text-opacity: 1;
  color: rgb(89 71 207 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#7e77a2\] {
  --tw-text-opacity: 1;
  color: rgb(126 119 162 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#858297\] {
  --tw-text-opacity: 1;
  color: rgb(133 130 151 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#93871A\] {
  --tw-text-opacity: 1;
  color: rgb(147 135 26 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#93871a\] {
  --tw-text-opacity: 1;
  color: rgb(147 135 26 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#959595\] {
  --tw-text-opacity: 1;
  color: rgb(149 149 149 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#BF2600\] {
  --tw-text-opacity: 1;
  color: rgb(191 38 0 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#BF483B\] {
  --tw-text-opacity: 1;
  color: rgb(191 72 59 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#FFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#bf2600\] {
  --tw-text-opacity: 1;
  color: rgb(191 38 0 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#d9d9d9\] {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity, 1));
}

.tw-text-\[\#ffbc2b\] {
  --tw-text-opacity: 1;
  color: rgb(255 188 43 / var(--tw-text-opacity, 1));
}

.tw-text-\[rgba\(0\,0\,0\,0\.85\)\] {
  color: rgba(0, 0, 0, 0.85);
}

.tw-text-\[rgba\(35\,29\,79\,0\.50\)\] {
  color: rgba(35, 29, 79, 0.5);
}

.tw-text-\[rgba\(39\,37\,34\,0\.5\)\] {
  color: rgba(39, 37, 34, 0.5);
}

.tw-text-\[rgba\(39\,37\,34\,0\.6\)\] {
  color: rgba(39, 37, 34, 0.6);
}

.tw-text-\[rgba\(39\,37\,34\,0\.60\)\] {
  color: rgba(39, 37, 34, 0.6);
}

.tw-text-\[rgba\(39\,37\,34\,0\.85\)\] {
  color: rgba(39, 37, 34, 0.85);
}

.tw-text-\[rgba\(39\,37\,34\,0\.86\)\] {
  color: rgba(39, 37, 34, 0.86);
}

.tw-text-\[rgba\(73\,58\,177\,0\.8\)\] {
  color: rgba(73, 58, 177, 0.8);
}

.tw-text-\[tw-text-\[\#272522\]\] {
  color: tw-text-[#272522];
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.tw-text-transparent {
  color: transparent;
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.tw-text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.tw-underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.tw-line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.\!tw-no-underline {
  -webkit-text-decoration-line: none !important;
  text-decoration-line: none !important;
}

.tw-decoration-solid {
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
}

.tw-decoration-auto {
  text-decoration-thickness: auto;
}

.tw-underline-offset-auto {
  text-underline-offset: auto;
}

.\!tw-opacity-100 {
  opacity: 1 !important;
}

.\!tw-opacity-40 {
  opacity: 0.4 !important;
}

.\!tw-opacity-50 {
  opacity: 0.5 !important;
}

.\!tw-opacity-60 {
  opacity: 0.6 !important;
}

.\!tw-opacity-80 {
  opacity: 0.8 !important;
}

.tw-opacity-0 {
  opacity: 0;
}

.tw-opacity-100 {
  opacity: 1;
}

.tw-opacity-30 {
  opacity: 0.3;
}

.tw-opacity-40 {
  opacity: 0.4;
}

.tw-opacity-50 {
  opacity: 0.5;
}

.tw-opacity-60 {
  opacity: 0.6;
}

.tw-opacity-80 {
  opacity: 0.8;
}

.tw-opacity-85 {
  opacity: 0.85;
}

.\!tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-\[0px_2px_12px_0px_rgba\(20\,20\,43\,0\.08\)\] {
  --tw-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  --tw-shadow-colored: 0px 2px 12px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-\[0px_2px_12px_0px_rgba\(73\,58\,177\,0\.20\)\] {
  --tw-shadow: 0px 2px 12px 0px rgba(73, 58, 177, 0.2);
  --tw-shadow-colored: 0px 2px 12px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-\[0px_3px_1px_0px_rgba\(0\,0\,0\,0\.12\)\] {
  --tw-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.12);
  --tw-shadow-colored: 0px 3px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-\[0px_7px_10px_0px_rgba\(209\,210\,214\,0\.30\)\] {
  --tw-shadow: 0px 7px 10px 0px rgba(209, 210, 214, 0.3);
  --tw-shadow-colored: 0px 7px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-\[2px_2px_4px_0px_rgba\(0\,0\,0\,0\.12\)\] {
  --tw-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
  --tw-shadow-colored: 2px 2px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\!tw-drop-shadow-\[0px_4px_4px_rgba\(0\2c _0\2c _0\2c _0\.15\)\] {
  --tw-drop-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.\!tw-transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-duration-100 {
  transition-duration: 100ms;
}

.tw-duration-200 {
  transition-duration: 200ms;
}

.tw-duration-300 {
  transition-duration: 300ms;
}

.\[-webkit-background-clip\:text\] {
  -webkit-background-clip: text;
}

.\[-webkit-text-fill-color\:initial\] {
  -webkit-text-fill-color: initial;
}

.\[-webkit-text-fill-color\:transparent\] {
  -webkit-text-fill-color: transparent;
}

.\[background\:radial-gradient\(132\.77\%_117\.66\%_at_49\.69\%_35\.33\%\2c
  \#FCFDFD_0\%\2c
  \#E9EEF6_100\%\)\] {
  background: radial-gradient(
    132.77% 117.66% at 49.69% 35.33%,
    #fcfdfd 0%,
    #e9eef6 100%
  );
}

.\[filter\:brightness\(0\)_invert\(1\)\] {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.\[transform\:rotate\(-47deg\)\] {
  -webkit-transform: rotate(-47deg);
  transform: rotate(-47deg);
}

.\[transform\:rotate\(180deg\)\] {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.\[transform\:translateX\(-50\%\)\] {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.\[transform\:translateY\(-50\%\)\] {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@font-face {
  font-family: "Roboto";

  src: local("Roboto"),
    url("assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-top: inherit !important;
  margin-bottom: inherit !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.top-0 {
  top: 0 !important;
}

.cursor-na {
  cursor: not-allowed;
}

.ql-tooltip {
  left: 0 !important;
}

.display_none {
  display: none;
}

.fit_content_modal {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.fit_content {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  width: intrinsic !important;
}

.height_fit_content {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  height: intrinsic !important;
}

#past_emails_list > .MuiToolbar-root {
  display: none;
}

.ra-input-email_body > div > div > div {
  background-color: white !important;
  border: 1px solid grey;
}

.ra-input-email_body {
  width: 100%;
  background-color: white;
}

.full_width {
  width: 100%;
}

.active_status_title {
  color: #ffffff;
}

.active_status {
  padding: 3px 5px;
  background-color: #00b779;
  color: #ffffff;
  border-radius: 5px;
}

.due_status_title {
  color: #ffffff;
}

.due_status {
  padding: 3px 5px;
  background-color: #ffab00;
  color: #ffffff;
  border-radius: 5px;
}

.over_due_status_title {
  color: #ffffff;
}

.over_due_status {
  padding: 3px 5px;
  background-color: #bf2600;
  color: #ffffff;
  border-radius: 5px;
}

.butterscotch_yellow {
  color: #ffab00 !important;
}

.inactive_status_title {
  color: #ffffff;
}

.inactive_status {
  padding: 3px 5px;
  background-color: #df645d;
  color: #ffffff;
  border-radius: 5px;
}

.color-primary,
.action_text {
  color: #493ab1 !important;
}

.action_text {
  cursor: pointer;
}

.disable_pointer {
  pointer-events: none !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.border-none {
  border: none !important;
}

.curson_not_allowed {
  cursor: not-allowed !important;
}

.info {
  font-size: 14px;
  color: #888;
}

.danger_text {
  color: #bf2600;
  font-size: 11px;
}

.max_height {
  height: 100%;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.dm_font {
  font-family: DM Sans;
}

.helvetica_neue {
  font-family: "Helvetica Neue";
}

.error_text {
  color: red;
  font-size: 12px;
}

.font_sm {
  font-size: 12px !important;
}

.font_md {
  font-size: 14px !important;
}

.danger_text {
  color: #bf2600 !important;
  font-size: 12px !important;
}

.react-date-picker__wrapper {
  border: none !important;
  font-size: 14px;
}

.react-date-picker__button {
  padding: 0 6px !important;
}

.alert_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 3rem 3rem;
  width: 50%;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: hidden;
  outline: none;
}

.modal_overflow {
  overflow: auto !important;
}

.custom_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 10px;
  width: 50%;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  outline: none;
}

.testimonial_modal {
  width: 600px;
}

.ql-editor {
  min-height: 100px;
}

.pointer {
  cursor: pointer;
}

.default_cursor {
  cursor: default;
}

#react-admin-title {
  visibility: hidden !important;
}

.svg_xl {
  font-size: 80px !important;
}

.add_svg {
  cursor: pointer;
  text-align: center;
  align-self: center;
  justify-self: center;
  width: 240px;
}

.parent {
  padding-left: 60px !important;
  padding-top: 20px;
}

.selector {
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
  border-color: #212121;
  margin-top: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

label[for="description"] {
  color: black;
  font-size: 20px !important;
}

/* td{
  word-break: break-all;
} */

.word_break {
  word-break: break-all;
}

.fixed_table_layout {
  table-layout: fixed !important;
}

button {
  outline: none !important;
}

.sidebarIsFixed {
  position: fixed;
  top: 3em;
  z-index: 101;
  height: 100%;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  margin-top: -50px !important;
  width: inherit !important;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  padding-top: 50px;
}

/* .sidebarIsFixed::-webkit-scrollbar {
  display: none;
} */

.sidebar {
  z-index: 101;
  background: white;
}

.single_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.vertical__one_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line_max_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line_max_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line_max_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.checklist_svg_xs {
  font-size: 15px !important;
}

.checklistTrue {
  fill: #1c8659 !important;
}

.checklistFalse {
  fill: #df635d !important;
}

.top_margin {
  margin-top: 20px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.bottom_margin {
  margin-bottom: 20px !important;
}

.right_margin {
  margin-right: 20px !important;
}

.left_margin {
  margin-left: 20px !important;
}

.no_padding {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.margin_auto {
  margin: auto !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.preview_modal {
  width: 90%;
}

.link {
  color: #0052cc !important;
}

.link:hover {
  text-decoration: underline !important;
}

.underline {
  text-decoration: underline !important;
}

.shimmer {
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.wrapper_shimmer {
  width: 0px;
  -webkit-animation: fullView 0.5s forwards linear;
  animation: fullView 0.5s forwards linear;
}

.shimmer_container {
  background-color: white;
  border: 1px solid #e0e0e3;
  padding: 15px;
  border-radius: 5px;
}

.loader-wrap {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.strikethrough {
  text-decoration: line-through;
}

/* TODO: see if this is actually needed?... or can we use `flex-grow-1` of bootstrap */

.flex_grow {
  flex-grow: 1;
}

.flex-basis-0 {
  flex-basis: 0;
}

.loader-wrap .circle-loader {
  height: 46px;
  width: 46px;
  padding: 2px;
  fill: transparent;
  stroke: #5764c6;
  stroke-width: 5;
  -webkit-animation: dash 2s ease infinite, rotate 2s linear infinite;
  animation: dash 2s ease infinite, rotate 2s linear infinite;
}

.loader-wrap .circle-loader.progress {
  background: none;
}

.tooltiptext {
  white-space: normal !important;
  visibility: hidden;
  width: 160px !important;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  left: 50%;
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
  top: 30px;
  padding: 5px 7px;
  font-size: 12px;
  /* Position the tooltip */
  position: absolute;
  z-index: 999;
}

.info {
  font-size: 16px !important;
}

.custom_tooltip:hover .tooltiptext {
  visibility: visible;
}

.custom_tooltip {
  position: relative;
}

.rbc-event-label {
  display: none !important;
}

.rbc-agenda-date-cell {
  background-color: white !important;
  color: #6c5dd3 !important;
}

.overflow_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.slick-dots {
  bottom: 9px !important;
}

.saveButtonToolbar {
  min-height: 0 !important;
  background-color: transparent !important;
  margin: auto;
}

.saveButtonToolbar button {
  background-color: #df635d !important;
  border-radius: 20px;
}

.simpleformonboarding .RaToolbar-toolbar-19 button,
.simpleformonboarding .RaToolbar-toolbar-11 button {
  background-color: #df635d !important;
  margin-bottom: 30px;
  border-radius: 20px;
}

.disableLeftClick {
  pointer-events: none;
}

.recharts-cartesian-axis-tick-value {
  font-size: 12px !important;
}

.listingDropdown12 {
  margin-top: 40px !important;
}

.descriptionModal .ql-editor {
  height: calc(100vh - 290px) !important;
  padding-bottom: 150px !important;
}

/**
 * TODO: refactor `attendanceTracking` css class
 * currently being used in src/ui/pages/AttendanceTracking/pages/CalendarView.js
 */

.attendanceTracking .rbc-event,
.attendanceTracking .rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
  color: #313b4f !important;
}

.descriptionModal .ql-toolbar {
  padding-bottom: 6px !important;
  border-bottom: solid 1px #ededed !important;
}

.composeEmail .simple-form .MuiCardContent-root {
  padding: 0px !important;
  background-color: rebeccapurple !important;
}

.composeEmail .MuiFormControl-marginDense {
  margin-top: 0px !important;
}

.composeEmail .simple-form .MuiCardContent-root:last-child {
  padding: 0px !important;
}

.composeEmail .simple-form .MuiCardContent-root:first-child {
  padding: 0px !important;
}

.composeEmail .ra-rich-text-input .ql-toolbar.ql-snow {
  background-color: #f9f9f9;
  padding: 8px;
  margin: 0;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  z-index: 10;
  display: inline-block !important; */
}

.composeEmail .ra-input-email_body > div > div > div {
  border: transparent;
}

/* Scrollbar customisation */

*::-webkit-scrollbar {
  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 10px;
}

*::-webkit-scrollbar:horizontal {
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 1px solid #ddd;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f8f7fa;
}

.font_300 {
  font-weight: 300 !important;
}

.font_400 {
  font-weight: 400 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

#auto_recorded_chip .exly--MuiChip-label {
  font-size: 8px !important;
}

.mobile_footer {
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-top: 2px solid #e7e6e4;
  box-sizing: border-box;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1101;
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 1439px) {
  .tab_top_margin {
    margin-top: 10px;
  }
}

@media screen and (max-width: 780px) {
  .descriptionModal .ql-editor {
    height: calc(100vh - 390px) !important;
  }

  .alert_modal {
    width: 92%;
    overflow: auto;
  }

  .rbc-toolbar {
    font-size: 10px !important;
  }

  .rbc-toolbar-label {
    font-weight: 800 !important;
  }

  .rbc-toolbar button {
    padding: 2px 6px !important;
  }

  .sidebarIsFixed {
    top: 0;
    height: 100%;
  }

  td {
    word-break: inherit;
  }

  .testimonial_modal {
    width: 80%;
  }

  .MuiInputBase-input {
    font: menu !important;
  }

  .parent {
    padding-left: 15px !important;
  }

  .mobile_row {
    margin: 0 15px 10px 0 !important;
  }

  .add_svg {
    display: none;
  }

  #save_btn {
    margin: 0 0 0 15px;
  }

  .fixed_table_layout {
    table-layout: auto !important;
  }

  .mobile_table {
    width: 90vw !important;
    overflow: auto !important;
    padding: 0 0 100px 10px !important;
  }

  .mobile_filter {
    width: 75vw;
  }

  .top_margin {
    margin-top: 15px !important;
  }

  .bottom_margin {
    margin-bottom: 15px !important;
  }

  .right_margin {
    margin-right: 15px !important;
  }

  .left_margin {
    margin-left: 15px !important;
  }

  .mobile_top_margin {
    margin-top: 10px;
  }

  .mobile_bottom_margin {
    margin-bottom: 10px;
  }

  .mobile_right_margin {
    margin-right: 10px;
  }

  .mobile_left_margin {
    margin-left: 10px;
  }

  .mobile_no_padding {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .saveButtonToolbar {
    background-color: white !important;
    margin: auto;
    height: 64px !important;
  }

  .saveButtonToolbar button {
    background-color: #df635d !important;
    margin-bottom: 0px;
    margin: auto;
  }

  .simpleformonboarding .RaToolbar-spacer-16 {
    height: 0em !important;
  }

  .fit_content_modal {
    width: 92% !important;
  }

  .MuiToolbar-gutters {
    padding-left: 0 !important;
  }

  td {
    min-width: 180px;
  }

  #fc_frame {
    bottom: 25px !important;
  }

  .listingDropdown12 {
    margin-top: 50px !important;
  }

  .custom_modal {
    width: 90%;
  }
}

@-webkit-keyframes fullView {
  100% {
    width: 100%;
  }
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -25;
  }

  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -25;
  }

  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
DEV - we will need to start adding variables from creatorTheme 
used in styles.js files to avoid slow js css and replaces them with css files.
*/

:root {
  --THEME_PALETTE_SECONDARY_SHADE_100: #e7e6e4;
  --THEME_PALETTE_PRIMARY_MAIN: #493ab1;
}

/* if any changes done in this file pls run yarn tailwind:start to incorporate the changes in output.css
index.css is not directly imported now in index.js
*/

.hover\:tw-bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:tw-underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

@media not all and (min-width: 768px) {
  .max-md\:tw-static {
    position: static;
  }

  .max-md\:tw-fixed {
    position: fixed;
  }

  .max-md\:tw-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .max-md\:tw-inset-\[auto_0_52px_0\] {
    inset: auto 0 52px 0;
  }

  .max-md\:tw-right-\[-25px\] {
    right: -25px;
  }

  .max-md\:tw-top-0 {
    top: 0px;
  }

  .max-md\:\!tw-m-\[0_auto_42px_auto\] {
    margin: 0 auto 42px auto !important;
  }

  .max-md\:tw-m-\[20px_16px\] {
    margin: 20px 16px;
  }

  .max-md\:tw-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .max-md\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .max-md\:tw-mx-\[-16px\] {
    margin-left: -16px;
    margin-right: -16px;
  }

  .max-md\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .max-md\:\!tw-mt-0 {
    margin-top: 0px !important;
  }

  .max-md\:\!tw-mt-\[8px\] {
    margin-top: 8px !important;
  }

  .max-md\:tw-mb-2 {
    margin-bottom: 0.5rem;
  }

  .max-md\:tw-mb-3 {
    margin-bottom: 0.75rem;
  }

  .max-md\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .max-md\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .max-md\:tw-mb-\[20px\] {
    margin-bottom: 20px;
  }

  .max-md\:tw-mt-4 {
    margin-top: 1rem;
  }

  .max-md\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .max-md\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .max-md\:tw-mt-\[-12px\] {
    margin-top: -12px;
  }

  .max-md\:tw-mt-\[-32px\] {
    margin-top: -32px;
  }

  .max-md\:tw-mt-\[4px\] {
    margin-top: 4px;
  }

  .max-md\:tw-mt-\[6px\] {
    margin-top: 6px;
  }

  .max-md\:\!tw-block {
    display: block !important;
  }

  .max-md\:\!tw-inline-block {
    display: inline-block !important;
  }

  .max-md\:tw-flex {
    display: flex;
  }

  .max-md\:\!tw-hidden {
    display: none !important;
  }

  .max-md\:tw-hidden {
    display: none;
  }

  .max-md\:tw-aspect-\[1\/1\] {
    aspect-ratio: 1/1;
  }

  .max-md\:tw-h-\[50px\] {
    height: 50px;
  }

  .max-md\:tw-min-h-0 {
    min-height: 0px;
  }

  .max-md\:tw-min-h-\[101px\] {
    min-height: 101px;
  }

  .max-md\:\!tw-w-full {
    width: 100% !important;
  }

  .max-md\:tw-w-full {
    width: 100%;
  }

  .max-md\:\!tw-min-w-0 {
    min-width: 0px !important;
  }

  .max-md\:tw-max-w-\[80\%\] {
    max-width: 80%;
  }

  .max-md\:tw-max-w-none {
    max-width: none;
  }

  .max-md\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .max-md\:tw-flex-row {
    flex-direction: row;
  }

  .max-md\:tw-flex-col {
    flex-direction: column;
  }

  .max-md\:tw-items-start {
    align-items: flex-start;
  }

  .max-md\:tw-items-center {
    align-items: center;
  }

  .max-md\:tw-justify-between {
    justify-content: space-between;
  }

  .max-md\:tw-gap-0 {
    gap: 0px;
  }

  .max-md\:tw-gap-2 {
    gap: 0.5rem;
  }

  .max-md\:tw-gap-3 {
    gap: 0.75rem;
  }

  .max-md\:\!tw-rounded {
    border-radius: 0.25rem !important;
  }

  .max-md\:tw-rounded-2xl {
    border-radius: 1rem;
  }

  .max-md\:tw-rounded-lg {
    border-radius: 0.5rem;
  }

  .max-md\:tw-rounded-md {
    border-radius: 0.375rem;
  }

  .max-md\:tw-rounded-none {
    border-radius: 0px;
  }

  .max-md\:\!tw-border {
    border-width: 1px !important;
  }

  .max-md\:\!tw-border-0 {
    border-width: 0px !important;
  }

  .max-md\:tw-border-0 {
    border-width: 0px;
  }

  .max-md\:tw-border-\[1px\] {
    border-width: 1px;
  }

  .max-md\:tw-border-b-\[0\.5px\] {
    border-bottom-width: 0.5px;
  }

  .max-md\:tw-border-t-\[1px\] {
    border-top-width: 1px;
  }

  .max-md\:\!tw-border-solid {
    border-style: solid !important;
  }

  .max-md\:tw-border-solid {
    border-style: solid;
  }

  .max-md\:\!tw-border-none {
    border-style: none !important;
  }

  .max-md\:tw-border-none {
    border-style: none;
  }

  .max-md\:\!tw-border-\[\#B0AEBC\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(176 174 188 / var(--tw-border-opacity, 1)) !important;
  }

  .max-md\:tw-border-\[\#D9D9D9\] {
    --tw-border-opacity: 1;
    border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  }

  .max-md\:tw-border-\[\#E1E1E1\] {
    --tw-border-opacity: 1;
    border-color: rgb(225 225 225 / var(--tw-border-opacity, 1));
  }

  .max-md\:tw-border-b-\[\#C6C6C8\] {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(198 198 200 / var(--tw-border-opacity, 1));
  }

  .max-md\:\!tw-bg-\[\#EEE\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1)) !important;
  }

  .max-md\:tw-bg-\[\#00B779\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 183 121 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-\[\#BF483B\] {
    --tw-bg-opacity: 1;
    background-color: rgb(191 72 59 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-\[\#F3F2F8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 242 248 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-\[\#FAFAFA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-\[\#FCFBFE\] {
    --tw-bg-opacity: 1;
    background-color: rgb(252 251 254 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .max-md\:tw-bg-\[linear-gradient\(101deg\2c
    _\#7365CD_-242\.86\%\2c
    _rgba\(115\2c
    101\2c
    205\2c
    0\.00\)_90\.6\%\)\] {
    background-image: linear-gradient(
      101deg,
      #7365cd -242.86%,
      rgba(115, 101, 205, 0) 90.6%
    );
  }

  .max-md\:\!tw-p-0 {
    padding: 0px !important;
  }

  .max-md\:\!tw-p-2 {
    padding: 0.5rem !important;
  }

  .max-md\:\!tw-p-3 {
    padding: 0.75rem !important;
  }

  .max-md\:\!tw-p-4 {
    padding: 1rem !important;
  }

  .max-md\:tw-p-0 {
    padding: 0px;
  }

  .max-md\:tw-p-4 {
    padding: 1rem;
  }

  .max-md\:tw-p-\[16px\] {
    padding: 16px;
  }

  .max-md\:\!tw-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .max-md\:\!tw-px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .max-md\:\!tw-px-\[10px\] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .max-md\:\!tw-px-\[16px\] {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .max-md\:\!tw-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .max-md\:\!tw-py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .max-md\:tw-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .max-md\:tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .max-md\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .max-md\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .max-md\:tw-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .max-md\:tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .max-md\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .max-md\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .max-md\:tw-py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .max-md\:tw-py-\[5px\] {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .max-md\:\!tw-pb-0 {
    padding-bottom: 0px !important;
  }

  .max-md\:\!tw-pl-\[17px\] {
    padding-left: 17px !important;
  }

  .max-md\:\!tw-pr-\[10px\] {
    padding-right: 10px !important;
  }

  .max-md\:\!tw-pt-0 {
    padding-top: 0px !important;
  }

  .max-md\:\!tw-pt-3 {
    padding-top: 0.75rem !important;
  }

  .max-md\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .max-md\:tw-pl-4 {
    padding-left: 1rem;
  }

  .max-md\:tw-pr-0 {
    padding-right: 0px;
  }

  .max-md\:tw-pt-0 {
    padding-top: 0px;
  }

  .max-md\:tw-pt-3 {
    padding-top: 0.75rem;
  }

  .max-md\:tw-text-left {
    text-align: left;
  }

  .max-md\:tw-text-center {
    text-align: center;
  }

  .max-md\:\!tw-text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .max-md\:\!tw-text-\[14px\] {
    font-size: 14px !important;
  }

  .max-md\:\!tw-text-\[16px\] {
    font-size: 16px !important;
  }

  .max-md\:\!tw-text-\[32px\] {
    font-size: 32px !important;
  }

  .max-md\:\!tw-text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .max-md\:\!tw-text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .max-md\:tw-text-\[12px\] {
    font-size: 12px;
  }

  .max-md\:tw-text-\[8px\] {
    font-size: 8px;
  }

  .max-md\:tw-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .max-md\:tw-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .max-md\:tw-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .max-md\:tw-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .max-md\:tw-font-medium {
    font-weight: 500;
  }

  .max-md\:\!tw-text-\[\#8F87B4\] {
    --tw-text-opacity: 1 !important;
    color: rgb(143 135 180 / var(--tw-text-opacity, 1)) !important;
  }

  .max-md\:\!tw-text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
  }

  .max-md\:tw-text-\[\#008734\] {
    --tw-text-opacity: 1;
    color: rgb(0 135 52 / var(--tw-text-opacity, 1));
  }

  .max-md\:tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  .max-md\:tw-shadow-\[0px_3px_6px_0px_rgba\(0\2c 0\2c 0\2c 0\.05\)\] {
    --tw-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 3px 6px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .max-md\:tw-shadow-\[0px_7px_10px_0px_rgba\(209\2c 210\2c 214\2c 0\.30\)\] {
    --tw-shadow: 0px 7px 10px 0px rgba(209, 210, 214, 0.3);
    --tw-shadow-colored: 0px 7px 10px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .max-md\:tw-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .max-md\:\[background\:linear-gradient\(90deg\2c
    _\#D8EEE0_0\%\2c
    _\#E4F5EA_50\%\2c
    _\#D8EEE0_100\%\)\] {
    background: linear-gradient(90deg, #d8eee0 0%, #e4f5ea 50%, #d8eee0 100%);
  }

  .max-md\:\[background\:white\] {
    background: white;
  }

  .max-md\:\[transform\:rotate\(43\.682deg\)\] {
    -webkit-transform: rotate(43.682deg);
    transform: rotate(43.682deg);
  }
}

@media (min-width: 768px) {
  .md\:tw-left-\[-25px\] {
    left: -25px;
  }

  .md\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:\!tw-hidden {
    display: none !important;
  }

  .md\:tw-h-\[38px\] {
    height: 38px;
  }

  .md\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:tw-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:\!tw-w-\[129px\] {
    width: 129px !important;
  }
}
